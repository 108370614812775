export const bancolombiaConfig = {
  data: {"data": {
    "bankId": "BANCOLOMBIA",
    "type": "COMPRAS_Y_RECAUDOS",
    "nature": "DINAMICO",
    "creationInformation": {
      "documentType": "CC",
      "documentNumber": "71264987",
      "user": "PaginaWeb"
    },
    "customers": {
      "documentType": "CC",
      "documentNumber": "11",
      "name": "Sin info"
    },
    "accounts": {
      "type": "CUENTA_DE_AHORRO",
      "number": "95929871317"
    },
    "commerce": {
      "name": "Mega Papel",
      "category": "EDUCACION",
      "transactionUrl": "",
      "salesPoint": {
        "id": "Local",
        "name": "Local"
      },
      "checkout": {
        "id": "",
        "name": ""
      },
      "seller": {
        "id": "71264987-5",
        "name": "Megapapel"
      }
    },
    "transactions": {
      "businessReference": "Impresión Mega Papel",
      "amount": 1,
      "transactionReference": "Impresion Mega Papel"
    },
    "aditionalNotificacion": [
      {
        "contactPhone": "",
        "email": ""
      }
    ]
  }},
  headers: {
    "Content-Type": "application/vnd.bancolombia.v4+json",
    "Accept":"application/vnd.bancolombia.v4+json",
    "Client-secret":"7fc95cd24e0b5c90c505c7f97e70951e",
    "Client-id":"bfb29938b664035bf43784891645b46b"
  },
};