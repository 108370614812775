import React, { useState } from "react";


function FileUpload({ onFileUpload, onError }) {
  const [file, setFile] = useState(null);

  const handleChange = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      // Validar que el archivo sea un PDF o una imagen (JPEG, PNG)
      if (file.type === "application/pdf" || // Para archivos PDF
        file.type === "image/png" ||        // Para imágenes PNG
        file.type === "image/jpeg" ||
        file.type === "image/jpg"   ) {
        setFile(file);
        onFileUpload(file); // Llamar a la función para manejar el archivo
      } else {
        onError("Se debe ingresar un archivo PDF o una imagen."); // Llamar a la función para manejar el error
        console.log("Se debe ingresar un archivo PDF o una imagen.");
      }
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      onError("No se ha seleccionado ningún archivo.");
      return;
    }

  };



  return (
    <div className="file-upload-container">
      <form onSubmit={handleSubmit}>
        {/* <input className="custom-file-input"  type="file" name="file" onChange={handleChange} /> */}
        <label className="custom-file-upload">
          <input type="file" name="file" onChange={handleChange} />
          Seleccionar archivo
        </label>

      </form>
      
    </div>
  );
}

export default FileUpload;
